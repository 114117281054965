* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.mainPage {
  background-color: rgb(240, 243, 245);
}

.fullHeight {
  height: calc(100vh - 60px);
}

.mainPage {
  padding: 20px;
  overflow: scroll;
  margin-bottom: 10px;
}

.m-1 {
  margin: 10px;
}

.sidebarGreen {
  color: rgb(45, 197, 139);
}
.sidebarGreenBg {
  background-color: rgb(45, 197, 139);
}

.sidebarGray {
  color: rgb(58, 67, 87);
}

.sidebarGrayBg {
  background-color: rgb(58, 67, 87);
}

.sidebarLightGray {
  color: rgb(120, 130, 146);
}

.sidebarLightGrayBg {
  background-color: rgb(120, 130, 146);
}


body {
  width: 100%
}
.grandparent {
  width: 100%
}
.parent {
  width: 100%
}
.tableContainer {
  width: 100%
}

@media only screen and (max-width: 641px) {
  .responsiveTitle{
    justify-content: center!important;
  }
 
}

/* .table {
  width:"95%";
  display :"block";
  overflow-x: "auto";
  } */

